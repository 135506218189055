<template>
  <div class="permission-container">权限受限</div>
</template>
<script>
export default {
  name: "permission",
};
</script>
<style>
.permission-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  font-size: 2rem;
}
</style>